import { AUTH, AUTH_FAIL, REGISTER } from '../action.type';
const intialState = {
  authUser: null,
  register: null,
  error: null
};

export const authReducer = (state = intialState, action) => {
  switch (action.type) {
    case AUTH:
      return {
        ...state,
        authUser: action.payload,
      };
    case AUTH_FAIL:
      return {
        ...state,
        authUser: null,
        error: action.payload
      }
    case REGISTER:
      return {
        ...state,
        register: action.payload.data.data,
      };
    default:
      return state;
  }
};
