import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom'
import { CountryCodes } from '../Assets/StaticData/countrycode';
import API from '../API';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_FAIL } from '../Redux/action.type';
import { handleRegister } from '../Redux/Action/auth.action';
import ReCAPTCHA from 'react-google-recaptcha';

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register } = useSelector((state) => state.authReducer);
    const [registerData, setRegisterData] = useState({
        phone: '',
        last_name: '',
        first_name: '',
        password: '',
        email: ''
    })
    const [addressData, setAddressData] = useState({
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        pincode: '',
        country: ''
    })
    const [countryCode, setCountryCode] = useState('+91')
    const [eyeopen, setEyeopen] = useState(false);
    const [allStateName, setAllStateName] = useState()
    const [allCityName, setAllCityName] = useState()
    const [numErr, setNumErr] = useState(false)
    const [isVarified, setIsVarified] = useState(false);
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    const [nPassError, setNPassError] = useState(false);
    const handleChange = (e) => {
        setRegisterData({ ...registerData, [e.target.name]: e.target.value })
    }
    const handleGetStateName = async () => {
        try {
            const ApiCall = await API({ url: `/client/getStateName`, method: 'get' });
            setAllStateName(ApiCall?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        handleGetStateName();
    }, []);
    useEffect(() => {
        if (register?.token) {
            navigate('/')
        }
    }, [register]);
    const handleGetCityName = async (statename) => {
        try {
            const ApiCall = await API({
                url: `/client/getDataFromStateName`, method: 'get', params: {
                    StateName: (statename?.charAt(0).toUpperCase() + statename?.slice(1).toLowerCase() || addressData?.state?.charAt(0).toUpperCase() + addressData?.state?.slice(1).toLowerCase())
                }
            });
            setAllCityName(ApiCall?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }
    const handleGetDataFromPincode = async () => {
        try {
            const ApiCall = await API({ url: `/client/getDataFromPincode`, method: 'get', params: { pincode: addressData?.pincode } });
            if (ApiCall?.data?.data) {
                handleGetCityName(ApiCall?.data?.data?.StateName)
                setAddressData({
                    ...addressData,
                    state: ApiCall?.data?.data?.StateName,
                    city: ApiCall?.data?.data?.District
                });
            }
        } catch (error) {
            dispatch({
                type: AUTH_FAIL,
                payload: error?.response?.data?.error,
            })
            setAddressData({
                ...addressData,
                state: '',
                city: ''
            })
            handleGetCityName('')
        }
    }
    const filterOptionsForContry = (options, state) => {
        const normalizedInputValue = state.inputValue?.toLowerCase();
        return options.filter((option) => {
            const combinedFields = `${option?.code?.toLowerCase()} ${option?.dial_code?.toLowerCase()} ${option?.name?.toLowerCase()}}`;
            return combinedFields.includes(normalizedInputValue);
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(handleRegister({ ...registerData, addressData, contry_code: countryCode }));
    };
    return (
        <section className="!bg-transparent">
            <div className="flex flex-col items-center justify-center px-6 py-8 max-w-screen">
                <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-black">
                    <img
                        className="inline-block max-w-[100px] min-[388px]:max-w-[140px] lg:max-w-full"
                        src="images/logo.svg"
                        alt="logo"
                    />
                </a>
                <div className="w-full bg-transparent border border-[#e5e5e5] rounded-lg shadow md:mt-0 sm:w-8/12 xl:w-1/3 ">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-black md:text-2xl">
                            Sign up to your account
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={(e) => handleSubmit(e)}>
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block mb-2 text-sm font-medium text-black"
                                >
                                    Email<span className="text-red-500 ms-1">*</span>
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                    placeholder="name@company.com"
                                    value={registerData?.email}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block mb-2 text-sm font-medium !text-black dark:text-white"
                                >
                                    Password<span className="text-red-500 ms-1">*</span>
                                </label>
                                <div className='flex items-center relative justify-between'>
                                    <input
                                        type={eyeopen ? 'text' : 'password'}
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                        value={registerData?.password}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={(e) => {
                                            if (passwordRegx?.test(e.target.value)) {
                                                setNPassError(false);
                                            } else {
                                                setNPassError(true);
                                            }
                                        }}
                                        required
                                    />
                                    {!eyeopen ? (
                                        <div
                                            onClick={() => {
                                                setEyeopen(!eyeopen);
                                            }}
                                            className='cursor-pointer absolute right-2'
                                        >
                                            <Eye />
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setEyeopen(!eyeopen);
                                            }}
                                            className='cursor-pointer absolute right-2'
                                        >
                                            <EyeSlash />
                                        </div>
                                    )}
                                </div>
                                {nPassError ? <p className='m-0 text-red-400 font-semibold'>Password must be 8 character long contain 1 letter upperCase 1 letter lowerCase 1 special character 1 letter numeric</p> : ''}
                            </div>
                            <div className="grid grid-cols-2 space-x-3 w-full">
                                <div>
                                    <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-sm font-medium text-black"
                                    >
                                        First Name
                                    </label>
                                    <input
                                        type="first_name"
                                        name="first_name"
                                        id="first_name"
                                        className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                        placeholder="name@company.com"
                                        value={registerData?.first_name}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="last_name"
                                        className="block mb-2 text-sm font-medium text-black"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        type="last_name"
                                        name="last_name"
                                        id="last_name"
                                        className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                        placeholder="name@company.com"
                                        value={registerData?.last_name}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    className="text-[13px] font-medium capitalize mb-1"
                                    htmlFor="phone"
                                >
                                    Phone Number
                                </label>
                                <div className="flex items-start contryCode">
                                    <Autocomplete
                                        className="autocomplete w-28 rounded-lg focus:border-[#ffe15f]"
                                        options={CountryCodes}
                                        filterOptions={filterOptionsForContry}
                                        value={CountryCodes?.find((x) => x?.dial_code === countryCode || null).dial_code}
                                        onChange={(e, v) => {
                                            if (v?.dial_code) {
                                                setCountryCode(v?.dial_code ?? '')
                                            } else {

                                            }
                                        }
                                        }
                                        renderOption={(props, option) => (
                                            <Box key={option.code} component="li" sx={{ '& > img': { mr: 1, fontSize: '10px' } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    alt={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                />
                                                <p className="text-sm flex items-center">{option.dial_code}</p>
                                            </Box>
                                        )}
                                        renderInput={(name) => (
                                            <TextField onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} placeholder='Country' {...name} />
                                        )}
                                    />
                                    <input
                                        min={1}
                                        maxLength="10"
                                        type="number"
                                        value={registerData?.phone}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 10) {  // Check if the length is less than or equal to 10
                                                handleChange(e); setNumErr(false)
                                            }
                                        }}
                                        name="phone"
                                        onBlur={() => {
                                            if (registerData?.phone?.length > 0 && registerData?.phone?.length !== 10) {
                                                setNumErr(true);
                                            } else {
                                                setNumErr(false);
                                            }
                                        }}
                                        className="relative bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                        placeholder="Phone number"
                                    />
                                </div>
                                {registerData?.phone?.length > 0 && registerData?.phone?.length !== 10 && numErr && (
                                    <p className='text-xs text-red-500'>Phone number should be 10 digits</p>
                                )}
                            </div>
                            <div>
                                <label
                                    className="text-[13px] font-medium capitalize mb-1"
                                    htmlFor="country"
                                >
                                    Country
                                    <span className='text-red-500 ms-1 inline-block text-sm'>*</span>
                                </label>
                                <Autocomplete
                                    className='autocomplete'
                                    options={CountryCodes ? CountryCodes : []}
                                    getOptionLabel={(item) => item.name}
                                    filterOptions={filterOptionsForContry}
                                    onChange={(e, v) => v ? setAddressData({ ...addressData, country: v?.name }) : ''}
                                    renderOption={(props, option) => (
                                        <Box key={option.code} component="li" sx={{ '& > img': { mr: 1, fontSize: '10px' } }} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt="flag"
                                            />
                                            <p className="text-sm flex items-center">{option.name}</p>
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder='Select countries'
                                            className='cursor-pointer'
                                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                            value={params?.inputValue}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <label
                                    className="text-[13px] font-medium capitalize mb-1"
                                    htmlFor="email"
                                >
                                    Address line 1
                                </label>
                                <input
                                    type="text"
                                    value={addressData?.addressLine1}
                                    onChange={(e) => { setAddressData({ ...addressData, addressLine1: e.target.value }) }}
                                    name="addressLine1"
                                    className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                    placeholder="Address Line 1"
                                />
                            </div>
                            <div>
                                <label
                                    className="text-[13px] font-medium capitalize mb-1"
                                    htmlFor="email"
                                >
                                    Address line 2
                                </label>
                                <input
                                    value={addressData?.addressLine2}
                                    name="addressLine2"
                                    onChange={(e) => { setAddressData({ ...addressData, addressLine2: e.target.value }) }}
                                    type="text"
                                    className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                    placeholder="Address Line 2"
                                />
                            </div>
                            <div className='grid grid-cols-3 space-x-3 mb-4'>
                                <div className='max-w-full'>
                                    <label
                                        className="text-[13px] font-medium capitalize mb-1"
                                        htmlFor="email"
                                    >
                                        pincode
                                    </label>
                                    <input
                                        id='Pincode'
                                        name='pincode'
                                        type='number'
                                        value={addressData?.pincode}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 6) {
                                                { setAddressData({ ...addressData, pincode: e.target.value }); setAllCityName(false); setNumErr(false) }
                                            }
                                        }}
                                        className='bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5'
                                        placeholder='Pincode'
                                        onBlur={() => {
                                            if (addressData?.pincode?.length === 6) {
                                                handleGetDataFromPincode();
                                                setNumErr(false);
                                            } else if (addressData?.pincode?.length > 0) {
                                                setNumErr(true);
                                            } else {
                                                setNumErr(false);
                                            }
                                        }}
                                    />
                                    {addressData?.pincode?.length > 0 && addressData?.pincode?.length !== 6 && numErr && (
                                        <p className='text-xs text-red-500'>Pincode should be 6 digits</p>
                                    )}
                                </div>
                                <div className='max-w-full'>
                                    <label
                                        className="text-[13px] font-medium capitalize mb-1"
                                        htmlFor="email"
                                    >
                                        State
                                    </label>
                                    <Autocomplete
                                        className='autocomplete'
                                        options={allStateName ? allStateName : []}
                                        getOptionLabel={(statename) => statename?.charAt(0).toUpperCase() + statename?.slice(1).toLowerCase()}
                                        value={allStateName ? allStateName?.find((x) => x === addressData?.state) : addressData?.state}
                                        onChange={(e, v) => setAddressData({ ...addressData, state: v })}
                                        onBlur={(e) => { handleGetCityName() }}
                                        renderInput={(Statename) => (
                                            <TextField
                                                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                                placeholder='Select state'
                                                className='cursor-pointer placeholder:text-black'
                                                {...Statename}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='max-w-full'>
                                    <label
                                        className="text-[13px] font-medium capitalize mb-1"
                                        htmlFor="email"
                                    >
                                        city
                                    </label>
                                    <Autocomplete
                                        className='autocomplete'
                                        options={allCityName ? allCityName : []}
                                        getOptionLabel={(cityname) => cityname.charAt(0).toUpperCase() + cityname.slice(1).toLowerCase()}
                                        value={allCityName ? allCityName.find((x) => x === addressData?.city) : addressData?.city}
                                        onChange={(e, v) => setAddressData({ ...addressData, city: v })}
                                        renderInput={(Cityname) => (
                                            <TextField
                                                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                                placeholder='Select city'
                                                className='cursor-pointer placeholder:text-black'
                                                {...Cityname}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-full mt-3">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        onChange={(value) => (value ? setIsVarified(true) : setIsVarified(false))}
                                    />
                                </div>
                            </div>
                            <button
                                type="submit"
                                disabled={!isVarified || numErr || !addressData?.country || !registerData?.email || !registerData?.password}
                                className={`${!isVarified || numErr || !addressData?.country || !registerData?.email || !registerData?.password ? 'opacity-50 cursor-not-allowed bg-[#222222]' : 'btn-black'} w-full  bg-gray-200  font-medium rounded-lg text-white text-sm px-5 py-2.5 text-center`}
                            >
                                Sign up
                            </button>
                            <p className="text-sm font-light text-black">
                                Already have an account?{' '}
                                <Link
                                    to="/login"
                                    className="font-medium text-black hover:underline"
                                >
                                    Sign in
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Register
