import React from 'react'
import ProductCard from './ProductCard'
const MoreFromWhispo = () => {
    
    return (
        <div className='container'>
            <h5 className='text-[26px] mb-5 font-medium'>More From Whispo</h5>
            <div className='grid grid-cols-5 gap-5'>
               <ProductCard />
               <ProductCard />
               <ProductCard bedge={'42%'} comparePrice={'30.00'}/>
               <ProductCard />
               <ProductCard />
            </div>
        </div>
    )
}

export default MoreFromWhispo