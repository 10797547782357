import API from "../../API";
import ToastNotification from "../../Controllers/ToastNotification";
import { CLIENTDATA, ERROR } from "../action.type";

export const getClientData = () => {
  return async (dispatch) => {
    try {
      const getClientForCustomer = await API({ url: '/client/getClientForCustomer', method: 'get'});
      if (getClientForCustomer?.data) {
        dispatch({
          type: CLIENTDATA,
          payload: getClientForCustomer,
        });
        localStorage.setItem("store_id", JSON.stringify(getClientForCustomer?.data?.data?.store_id))
      }
    } catch (error) {
      ToastNotification.error(error);
      dispatch({
        type: ERROR,
        payload: error?.response?.data?.error,
      });
    }
  };
};
