import React from 'react'

const Footer = () => {
    return (
        <footer className='bg-[#14345c] text-white py-20 w-full'>
            <div className='container grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5'>
                <div className='flex flex-col gap-3'>
                    <div className='logo'>
                        <img src="images/logo.svg" alt="logo" />
                    </div>
                    <p className='font-normal text-base'>Lorem ipsum dolor sit amet,consectetur adipiscing elit. Nullam a nibh eu neque consequat elementum.</p>
                </div>
                <div className='flex xl:items-end flex-col gap-3'>
                    <h6 className='text-[18px] font-semibold'>Online Shopping</h6>
                    <ul className='flex flex-col gap-2'>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>About Us</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Careers</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Social Responsibility</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Affiliate Program</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Business With Us</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Find a Store</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Press & Talent</a>
                        </li>
                    </ul>
                </div>
                <div className='flex xl:items-end flex-col gap-3'>
                    <h6 className='text-[18px] font-semibold'>Online Shopping</h6>
                    <ul className='flex flex-col gap-2'>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>About Us</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Careers</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Social Responsibility</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Affiliate Program</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Business With Us</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Find a Store</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Press & Talent</a>
                        </li>
                    </ul>
                </div>
                <div className='flex xl:items-end flex-col gap-3'>
                    <h6 className='text-[18px] font-semibold'>Online Shopping</h6>
                    <ul className='flex flex-col gap-2'>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>About Us</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Careers</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Social Responsibility</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Affiliate Program</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Business With Us</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Find a Store</a>
                        </li>
                        <li>
                            <a href="/" className='text-sm font-normal hover:underline hover:text-[#ffe15f] transition-all duration-200'>Press & Talent</a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer