import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { handleLogin } from '../Redux/Action/auth.action';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { authUser } = useSelector((state) => state.authReducer);
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })
    const [rememberMe, setRememberMe] = useState(false);
    const [eyeopen, setEyeopen] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(handleLogin(loginData, rememberMe))
    }
    useEffect(() => {
        if(authUser?.token) {
            navigate('/')
        }
    }, [authUser]);
    return (
        <section className="!bg-transparent">
            <div className="flex flex-col items-center justify-center px-6 py-8 max-w-screen">
                <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-black">
                    <img
                        className="inline-block max-w-[100px] min-[388px]:max-w-[140px] lg:max-w-full"
                        src="images/logo.svg"
                        alt="logo"
                    />
                </Link>
                <div className="w-full bg-transparent border border-[#e5e5e5] rounded-lg shadow md:mt-0 sm:w-8/12 xl:w-1/3 ">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-black md:text-2xl">
                            Sign in to your account
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={(e) => handleSubmit(e)}>
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block mb-2 text-sm font-medium text-black"
                                >
                                    Your email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                    placeholder="name@company.com"
                                    value={loginData?.email}
                                    onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                                    required
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block mb-2 text-sm font-medium !text-black dark:text-white"
                                >
                                    Password
                                </label>
                                <div className='flex items-center relative justify-between'>
                                    <input
                                        type={eyeopen ? 'text' : 'password'}
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                        value={loginData?.password}
                                        onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                                        required
                                    />
                                    {!eyeopen ? (
                                        <div
                                            onClick={() => {
                                                setEyeopen(!eyeopen);
                                            }}
                                            className='cursor-pointer absolute right-2'
                                        >
                                            <Eye />
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setEyeopen(!eyeopen);
                                            }}
                                            className='cursor-pointer absolute right-2'
                                        >
                                            <EyeSlash />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            id="remember"
                                            aria-describedby="remember"
                                            type="checkbox"
                                            className="w-4 h-4 border border-gray-300 rounded bg-transparent"
                                            value={rememberMe}
                                            onChange={(e) => setRememberMe(e.target.checked)}
                                            required
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="remember" className="text-black">
                                            Remember me
                                        </label>
                                    </div>
                                </div>
                                <Link
                                    to="/forgot-password"
                                    className="text-sm font-medium text-primary-600 hover:underline"
                                >
                                    Forgot password?
                                </Link>
                            </div>
                            <button
                                type="submit"
                                disabled={!loginData?.email || !loginData?.password}
                                className={`${!loginData?.email || !loginData?.password ? 'opacity-50 cursor-not-allowed' : 'btn-black'} w-full text-white bg-[#222222]  font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                            >
                                Sign in
                            </button>
                            <p className="text-sm font-light text-black">
                                Don’t have an account yet?{' '}
                                <Link
                                    to='/register'
                                    className="font-medium text-black hover:underline"
                                >
                                    Sign up
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login
