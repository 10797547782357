import React from 'react'
import HeroSection from '../Components/Home/HeroSection'
import Categories from '../Components/Home/Categories'
import ImageSlider from '../Components/Home/ImageSlider'
import Offer1 from '../Components/Home/Offer1'
import ImageGallery from '../Components/Home/ImageGallery'
import Offer2 from '../Components/Home/Offer2'
import Discount from '../Components/Home/Discount'
import Services from '../Components/Home/Services'

const Home = () => {
    return (
        <main className='flex flex-col overflow-x-hidden gap-[80px]'>
            <HeroSection />
            <Categories />
            <div>
                <ImageSlider />
                <Offer1 />
                <ImageGallery />
                <Offer2 />
            </div>
            <div>
                <Discount />
                <Services />
            </div>
        </main>
    )
}

export default Home