import React from 'react'
import { Truck, Plus, CreditCard, Minus } from 'feather-icons-react'

const ProductDetails = () => {
    return (
        <div className='grid container grid-cols-1 lg:grid-cols-2 gap-5'>
            <div className='py-10'>
                <div className='flex mb-4 items-start gap-5'>
                    <div className='min-w-[130px] flex flex-col gap-5 max-w-[130px]'>
                        <div className='w-full h-[173px] rounded-[10px] bg-black'></div>
                        <div className='w-full h-[173px] rounded-[10px] bg-black'></div>
                        <div className='w-full h-[173px] rounded-[10px] bg-black'></div>
                    </div>
                    <div className='w-full h-[600px] bg-black rounded-[10px] grow'></div>
                </div>
                <details className="group" open>
                    <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                        <h2 className="font-medium text-2xl">Description</h2>
                        <span className="relative size-5 shrink-0">
                            <Plus className="absolute inset-0 size-5 opacity-100 group-open:opacity-0"/>
                            <Minus className="absolute inset-0 size-5 opacity-0 group-open:opacity-100"/>
                        </span>
                    </summary>
                    <p className="mt-4 text-[13px] text-[#666666]">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tellus tempor, ultrices arcu
                        in, accumsan lacus. Pellentesque id nisl at leo sodales commodo. Nulla vitae euismod
                        ante, vitae faucibus elit.
                    </p>
                </details>
                <hr  className='border-gray-300 my-4'/>
                <details className="group">
                    <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                        <h2 className="font-medium text-2xl">Additional Infrmation</h2>
                        <span className="relative size-5 shrink-0">
                            <Plus className="absolute inset-0 size-5 opacity-100 group-open:opacity-0"/>
                            <Minus className="absolute inset-0 size-5 opacity-0 group-open:opacity-100"/>
                        </span>
                    </summary>
                    <p className="mt-4 text-[13px] text-[#666666]">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tellus tempor, ultrices arcu
                        in, accumsan lacus. Pellentesque id nisl at leo sodales commodo. Nulla vitae euismod
                        ante, vitae faucibus elit.
                    </p>
                </details>
            </div>
            <div className='flex flex-col py-10 gap-4'>
                <div className='flex flex-col gap-1.5'>
                    <h6 className='text-2xl'>T-shirt with chest pocket</h6>
                    <span className='text-2xl mb-1 font-semibold'>$30.00</span>
                    <div className='flex items-center gap-2'>
                        <Truck strokeWidth={1.5} className="w-5 h-5" />
                        <span className='flex items-center text-sm font-medium'><Plus strokeWidth={4} className="w-3 me-1 h-3" /> Add a Address</span>
                        <span className='text-[11px] py-[3px] px-2 bg-[#e5e5e5] rounded-[6px]'>Free Delivery</span>
                    </div>
                </div>
                <hr className='border-gray-300' />
                <div>
                    <span className='text-base font-medium'>Select Size</span>
                    <div className='flex flex-wrap items-center gap-2 mt-2'>
                        <span className='w-[60px] h-[30px] text-base bg-white border border-black rounded-full flex items-center justify-center'>S</span>
                        <span className='w-[60px] h-[30px] text-base bg-white border border-black rounded-full flex items-center justify-center'>M</span>
                        <span className='w-[60px] h-[30px] text-base bg-black text-white border border-black rounded-full flex items-center justify-center'>L</span>
                        <span className='w-[60px] h-[30px] text-base bg-white border border-black rounded-full flex items-center justify-center'>XL</span>
                        <span className='w-[60px] h-[30px] text-base bg-white border border-black rounded-full flex items-center justify-center'>XXL</span>
                        <span className='text-base flex items-center font-medium'>Size Chart <CreditCard strokeWidth={2} className="w-5 h-5 ms-1.5" /></span>
                    </div>
                </div>
                <div className='flex flex-wrap lg:flex-nowrap items-center gap-3'>
                    <span className='text-base font-medium'>Quantity</span>
                    <div className='border flex rounded-[10px] items-center gap-0.5 border-gray-300 p-[3px]'>
                        <button className='bg-[#f2f2f2] w-[30px] rounded-[8px] h-[35px] flex items-center justify-center'><Minus className="w-3 h-3" strokeWidth={3} /></button>
                        <input className='w-[30px] h-[35px] text-center text-sm' defaultValue={'01'} />
                        <button className='bg-[#f2f2f2] w-[30px] rounded-[8px] h-[35px] flex items-center justify-center'><Plus className="w-3 h-3" strokeWidth={3} /></button>
                    </div>
                    <button style={{ height: '42px', padding: '0', borderRadius: '10px' }} className='btn-yellow w-full block'>Add To Cart</button>
                </div>
                <button style={{ backgroundColor: 'black', borderRadius: '10px' }} className='btn-black w-full block'>Buy Now</button>
                <div>
                    <span className='text-base mb-1 block font-medium'>Product Details</span>
                    <p className='text-[13px] text-gray-600'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tellus tempor, ultrices arcu in, accumsan lacus. Pellentesque id nisl at leo sodales commodo. Nulla vitae euismod ante, vitae faucibus elit.<span className='text-black font-medium ms-1 text-[12px]'>VIEW MORE</span></p>
                </div>
                <div className='bg-[#f7f7f7] flex flex-col gap-5 p-5 rounded-[6px]'>
                    <div className='flex items-center justify-between'>
                        <div className='flex gap-2 items-center'>
                            <div className='w-[40px] h-[40px] rounded-[6px] bg-[#ffe15f]'></div>
                            <span className='text-base font-medium'>Whispo</span>
                        </div>
                        <button style={{ height: '38px', padding: '0px 30px', borderRadius: '6px' }} className='btn-outline-black'>View Shop</button>
                    </div>
                    <hr className='border-gray-300' />
                    <div className='flex items-center justify-between'>
                        <div>
                            <div className='flex items-center gap-2'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                    fill="#000"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-star"
                                >
                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                </svg>
                                <span className='font-medium text-base'>4.3</span>
                            </div>
                            <p className='text-[#333333] text-base'>12,000 Rating</p>
                        </div>
                        <div>
                            <span className='block font-medium text-base'>2000</span>
                            <p className='text-[#333333] text-base'>Followers</p>
                        </div>
                        <div>
                            <span className='block font-medium text-base'>150</span>
                            <p className='text-[#333333] text-base'>Products</p>
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <button style={{ backgroundColor: 'black', borderRadius: '10px' }} className='btn-black grow block'>Read More Review</button>
                    <span className='mx-8 text-center flex justify-center text-sm font-medium underline'>Privacy Policy</span>
                </div>
            </div>
        </div>
    )
}

export default ProductDetails