import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';

const Offer1 = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className='h-[80px] lg:h-[143px] w-screen slider-container overflow-hidden'  >
            <Slider dots={false}
                infinite={true}
                speed={5000}
                slidesToShow={windowWidth > 1300 ? 3 :
                    windowWidth > 991 && windowWidth <= 1200 ? 2 :
                        windowWidth > 768 && windowWidth <= 991 ? 2 :
                            windowWidth > 575 && windowWidth <= 768 ? 2 :
                                windowWidth > 100 && windowWidth <= 575 ? 1 : 1
                }
                slidesToScroll={2}
                autoplay={true}
                autoplaySpeed={0}
                variableWidth={true}
                cssEase="linear"
                className='mx-auto h-[80px] text-[46px] uppercase lg:h-[143px] '>
                <div className='flex flex-nowrap w-auto items-center justify-center mx-5 lg:mx-10 h-[80px] lg:h-[143px]'>
                    <span className='block'>What’s new in trends</span>
                </div>
                <div className='flex flex-nowrap w-auto items-center justify-center mx-5 lg:mx-10 h-[80px] lg:h-[143px]'>
                    <span className='block'>*</span>
                </div>
                <div className='flex flex-nowrap w-auto items-center justify-center mx-5 lg:mx-10 h-[80px] lg:h-[143px]'>
                    <span className='block'>Discover More by shop</span>
                </div>
                <div className='flex flex-nowrap w-auto items-center justify-center mx-5 lg:mx-10 h-[80px] lg:h-[143px]'>
                    <span className='block'>*</span>
                </div>
                <div className='flex flex-nowrap w-auto items-center justify-center mx-5 lg:mx-10 h-[80px] lg:h-[143px]'>
                    <span className='block'>Random Pics by what's you love</span>
                </div>
                <div className='flex flex-nowrap w-auto items-center justify-center mx-5 lg:mx-10 h-[80px] lg:h-[143px]'>
                    <span className='block'>*</span>
                </div>
            </Slider>
        </div >
    )
}

export default Offer1