import React from 'react'
import { User, ShoppingBag, ChevronRight, Heart, RefreshCcw, Search } from 'feather-icons-react'

const Header = () => {
    return (
        <header className='bg-[#292623]'>
            <div className='container h-[60px] lg:h-[90px] flex items-center justify-between gap-5'>
                <a href='/' className='logo'>
                    <img src="images/logo.svg" alt="logo svg" className='inline-block max-w-[100px] min-[388px]:max-w-[140px] lg:max-w-full' />
                </a>
                <div className='grow max-w-full flex justify-end items-center gap-3 min-[388px]:gap-5'>
                    <div className='search lg:grow'>
                        <form className="relative lg:bg-white text-sm mx-auto flex w-full items-center justify-between rounded-md lg:border">
                            <Search className="w-4 h-4 text-white left-3 lg:absolute lg:top-[50%] lg:-translate-y-[50%] lg:text-black lg:w-6 lg:h-6" strokeWidth={1.5} />
                            <input type="name" name="search" className="h-[30px] hidden lg:block lg:h-[50px] w-full rounded py-2 pl-6 lg:pl-12 pr-4 lg:pr-12  outline-none text-sm" placeholder="Search products & brads.." />
                        </form>
                    </div>
                    <div className='flex items-center text-white gap-2'>
                        <div>
                            <User className='w-4 h-4 lg:w-7 lg:h-7' strokeWidth={1.5} />
                        </div>
                        <div className='lg:block hidden'>
                            <span className='text-xs block'>MY ACCOUNT</span>
                            <span className='text-xs block cursor-pointer'>
                                <a href='/login' className='hover:underline transition-all duration-150 hover:text-[#ffe15f]'>Login</a> / 
                                <a href='/register' className='hover:underline ms-1 transition-all duration-150 hover:text-[#ffe15f]'>Signup</a>
                            </span>
                        </div>
                    </div>
                    <div className='flex items-center text-white gap-2'>
                        <div className='flex items-center gap-1.5'>
                            <ShoppingBag className='w-4 h-4 lg:w-7 lg:h-7' strokeWidth={1.5} />
                            <div className='bedge'>0</div>
                        </div>
                        <div className='lg:block hidden'>
                            <span className='text-xs block'>CHECKOUT</span>
                            <span className='text-xs block'>$156.75</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container flex flex-wrap items-center lg:justify-center pb-2 lg:h-[56px] h-auto'>
                <nav className='lg:flex-grow pb-3 lg:pb-0'>
                    <ul className='p-0 m-0 flex-wrap flex items-center gap-2.5 min-[325px]:gap-4 lg:gap-10 text-[11px] min-[388px]:text-xs lg:text-sm text-white'>
                        <li>
                            Home
                        </li>
                        <li>
                            Child Care
                        </li>
                        <li>
                            Electronics
                        </li>
                        <li>
                            Fashion
                        </li>
                        <li>
                            Exclusives
                        </li>
                        <li>
                            Specially
                        </li>
                        <li className='flex items-center'>
                            More <ChevronRight className='w-4 h-4' />
                        </li>
                        <li className='flex lg:hidden items-center text-white gap-1.5'>
                            <Heart className='h-3 w-3 min-[388px]:w-4 min-[388px]:h-4 lg:w-5 lg:h-5' strokeWidth={2} />
                            <div className='bedge'>0</div>
                        </li>
                        <li className='flex lg:hidden items-center text-white gap-1.5'>
                            <RefreshCcw className='h-3 w-3 min-[388px]:w-4 min-[388px]:h-4 lg:w-5 lg:h-5' strokeWidth={2} />
                            <div className='bedge'>0</div>
                        </li>
                    </ul>
                </nav>
                <div className='hidden lg:block pb-2 lg:pb-0'>
                    <div className='flex items-center justify-end gap-8'>
                        <div className='flex items-center text-white gap-1.5'>
                            <Heart className='w-4 h-4 lg:w-5 lg:h-5' strokeWidth={2} />
                            <div className='bedge'>0</div>
                        </div>
                        <div className='flex items-center text-white gap-1.5'>
                            <RefreshCcw className='w-4 h-4 lg:w-5 lg:h-5' strokeWidth={2} />
                            <div className='bedge'>0</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header