import React from 'react'
import { Link } from 'react-router-dom'

const PageNoFound = () => {
    return (
        <div className='min-h-[400px] flex flex-col items-center justify-center'>
            <span className='block text-[90px] m-0 font-extrabold'>404</span>
            <p className='text-xl m-0 font-medium'>Sorry we couldn't find this page.</p>
            <p className='text-lg font-normal text-gray-500'>But dont worry, you can find plenty of other things on our homepage.</p>
            <Link className='btn-yellow font-normal mt-4'>Back To Home</Link>
        </div>
    )
}

export default PageNoFound