import React from 'react'
import { Heart } from 'feather-icons-react'

const ProductCard = ({ bedge, comparePrice }) => {
    const StarFill = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 24 24"
                fill="#000"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-star"
            >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>
        )
    }
    const TagFill = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={11}
                height={11}
                fill="currentColor"
                viewBox="0 0 16 16"
            >
                <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
        )
    }
    return (
        <div className='flex flex-col gap-1'>
            <div className='w-full mb-1 bg-[#f3f3f3] h-[307px] rounded-[10px] relative'>
                {bedge && <span className='flex absolute justify-center p-[3px] rounded-[6px] top-1.5 right-2 items-center text-[11px] font-medium text-white gap-1 bg-[#e50000] align-middle'><TagFill />{bedge}</span>}
                <div className='absolute w-[40px] h-[40px] flex items-center justify-center bottom-1.5 right-2 bg-[#dedede] rounded-full'>
                    <Heart className="w-5 h-5" />
                </div>
            </div>
            <span className='text-[14px] block text-[#666666]'>T-shirt with chest pocket</span>
            <div className='flex items-center gap-1'>
                <StarFill />
                <StarFill />
                <StarFill />
                <StarFill />
                <StarFill />
                <span className='text-sm ms-1'>[4]</span>
            </div>
            {
                comparePrice ?
                    <div className='flex items-center gap-2'>
                        <span className='text-[14px] font-medium block text-[#e50000]'>$30.00</span>
                        <span className='text-[14px] font-medium block text-[#666666]'><del>${comparePrice}</del></span>
                    </div> :
                    <span className='text-[14px] font-medium block text-[#333333]'>$30.00</span>
            }
        </div>
    )
}

export default ProductCard