import React from 'react'
import ProductDetails from '../Components/Product/ProductDetails'
import MoreFromWhispo from '../Components/Product/MoreFromWhispo'
import RelatedSearchProducts from '../Components/Product/RelatedSearchProducts'
import ProductList from '../Components/Product/ProductList'

const Product = () => {
  return (
    <main className='flex flex-col pb-[80px] overflow-x-hidden gap-[80px]'>
        <ProductDetails />
        <MoreFromWhispo />
        <RelatedSearchProducts />
        <ProductList />
        <ProductList />
        <ProductList />
    </main>
  )
}

export default Product