import React from 'react'

const Discount = () => {
    return (
        <div className='container flex items-center justify-center flex-wrap gap-3'>
            <div className="discount-card bg-[#a8ece0]">
                <div>
                    <h5 className='text-[20px] md:text-[30px] mb-1'>Get Upto</h5>
                    <h3 className='text-[40px] md:text-[60px] mb-1 font-bold'>40% Off</h3>
                    <p className='text-[18px] md:text-[20px] mx-auto'>On the Trendiest Accessories</p>
                </div>
                <div className='discount-line'></div>
                <button className='btn-black mb-2 w-fit mx-auto'>SHOP NOW</button>
            </div>
            <div className="discount-card bg-[#ceb8ed]">
                <div>
                    <h5 className='text-[20px] md:text-[30px] mb-1'>Get Upto</h5>
                    <h3 className='text-[40px] md:text-[60px] mb-1 font-bold'>40% Off</h3>
                    <p className='text-[18px] md:text-[20px] mx-auto'>On the Trendiest Accessories</p>
                </div>
                <div className='discount-line'></div>
                <button className='btn-black mb-2 w-fit mx-auto'>SHOP NOW</button>
            </div>
            <div className="discount-card bg-[#d4e4fc]">
                <div>
                    <h5 className='text-[20px] md:text-[30px] mb-1'>Get Upto</h5>
                    <h3 className='text-[40px] md:text-[60px] mb-1 font-bold'>40% Off</h3>
                    <p className='text-[18px] md:text-[20px] mx-auto'>On the Trendiest Accessories</p>
                </div>
                <div className='discount-line'></div>
                <button className='btn-black mb-2 w-fit mx-auto'>SHOP NOW</button>
            </div>
            <div className="discount-card bg-[#f3e4b5]">
                <div>
                    <h5 className='text-[20px] md:text-[30px] mb-1'>Get Upto</h5>
                    <h3 className='text-[40px] md:text-[60px] mb-1 font-bold'>40% Off</h3>
                    <p className='text-[18px] md:text-[20px] mx-auto'>On the Trendiest Accessories</p>
                </div>
                <div className='discount-line'></div>
                <button className='btn-black mb-2 w-fit mx-auto'>SHOP NOW</button>
            </div>
            <div className="discount-card bg-[#fccafd]">
                <div>
                    <h5 className='text-[20px] md:text-[30px] mb-1'>Get Upto</h5>
                    <h3 className='text-[40px] md:text-[60px] mb-1 font-bold'>40% Off</h3>
                    <p className='text-[18px] md:text-[20px] mx-auto'>On the Trendiest Accessories</p>
                </div>
                <div className='discount-line'></div>
                <button className='btn-black mb-2 w-fit mx-auto'>SHOP NOW</button>
            </div>
        </div>
    )
}

export default Discount