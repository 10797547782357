import React from 'react'

const ImageGallery = () => {
    // const settings = {
    //     className: "center",
    //     centerMode: true,
    //     infinite: true,
    //     centerPadding: "60px",
    //     slidesToShow: 3,
    //     speed: 500
    //   };
    return (
        <div className="image-gallery">
            {/* <Slider {...settings}> */}
                <div className='px-2 overflow-hidden flex items-center gap-3'>
                    <div className='grid w-fit grid-cols-1 gap-3 '>
                        <div className='flex gap-3 w-fit'>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                        </div>
                        <div className='flex w-fit gap-3'>
                            <div className='h-[200px] min-w-[412px] max-w-[412px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                        </div>
                        <div className='flex w-fit gap-3'>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                        </div>
                    </div>
                    <div className='grid w-fit grid-cols-1 gap-3'>
                        <div className='flex w-fit gap-3'>
                            <div className='h-[200px] min-w-[412px] max-w-[412px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                        </div>
                        <div className='flex w-fit gap-3'>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[412px] max-w-[412px] bg-black rounded-[6px]'></div>
                        </div>
                        <div className='flex w-fit gap-3'>
                            <div className='h-[200px] min-w-[412px] max-w-[412px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                        </div>
                    </div>
                    <div className='grid w-fit grid-cols-1 gap-3'>
                        <div className='flex w-fit gap-3'>
                            <div className='h-[200px] min-w-[412px] max-w-[412px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                        </div>
                        <div className='flex w-fit gap-3'>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                        </div>
                        <div className='flex w-fit gap-3'>
                            <div className='h-[200px] min-w-[412px] max-w-[412px] bg-black rounded-[6px]'></div>
                            <div className='h-[200px] min-w-[200px] max-w-[200px] bg-black rounded-[6px]'></div>
                        </div>
                    </div>
                </div>
            {/* </Slider> */}
        </div>
    )
}

export default ImageGallery