import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'

const ImageSlider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className='slider-container h-[427px] overflow-hidden'>
            <Slider dots={false}
                infinite={true}
                speed={8000}
                slidesToShow={windowWidth > 1300 ? 5 :
                    windowWidth > 991 && windowWidth <= 1200 ? 4 :
                        windowWidth > 768 && windowWidth <= 991 ? 3 :
                            windowWidth > 575 && windowWidth <= 768 ? 2 :
                                windowWidth > 100 && windowWidth <= 575 ? 1 : 51
                }
                slidesToScroll={1}
                autoplay={true}
                cssEase="linear"
                variableWidth={true}
                autoplaySpeed={8000}
                className='mx-auto'>
                <div className='relative w-full'>
                    <img src="images/banner-01.webp" alt="banner-01" className='w-full h-full object-cover inline-block' />
                    <div className='text-center flex-col w-full text-white absolute bottom-4 left-[50%] -translate-x-[50%]'>
                        <span className='text-[20px] font-normal block'>Cosmetics</span>
                        <span className='text-[30px] font-normal block'>Starting From</span>
                        <span className='text-[30px] font-normal block'>$399</span>
                    </div>
                </div>
                <div className='relative'>
                    <img src="images/banner-02.webp" alt="banner-02" className='w-full object-cover inline-block' />
                    <div className='text-center flex-col w-full text-white absolute bottom-4 left-[50%] -translate-x-[50%]'>
                        <span className='text-[20px] font-normal block'>Hand Made</span>
                        <span className='text-[30px] font-normal block'>Starting From</span>
                        <span className='text-[30px] font-normal block'>$399</span>
                    </div>
                </div>
                <div className='relative'>
                    <img src="images/banner-03.webp" alt="banner-03" className='w-full object-cover inline-block' />
                    <div className='text-center flex-col w-full text-white absolute bottom-4 left-[50%] -translate-x-[50%]'>
                        <span className='text-[20px] font-normal block'>Fashion</span>
                        <span className='text-[30px] font-normal block'>Starting From</span>
                        <span className='text-[30px] font-normal block'>$399</span>
                    </div>
                </div>
                <div className='relative'>
                    <img src="images/banner-04.webp" alt="banner-04" className='w-full object-cover inline-block' />
                    <div className='text-center flex-col w-full text-white absolute bottom-4 left-[50%] -translate-x-[50%]'>
                        <span className='text-[20px] font-normal block'>Bags</span>
                        <span className='text-[30px] font-normal block'>Starting From</span>
                        <span className='text-[30px] font-normal block'>$399</span>
                    </div>
                </div>
                <div className='relative'>
                    <img src="images/banner-05.webp" alt="banner-05" className='w-full object-cover inline-block' />
                    <div className='text-center flex-col w-full text-white absolute bottom-4 left-[50%] -translate-x-[50%]'>
                        <span className='text-[20px] font-normal block'>Home Decor</span>
                        <span className='text-[30px] font-normal block'>Starting From</span>
                        <span className='text-[30px] font-normal block'>$399</span>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default ImageSlider