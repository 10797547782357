import axios from 'axios';

// Create an Axios instance with base URL and default headers
export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add request interceptor to include token and store ID headers
instance.interceptors.request.use(
  (config) => {
    const store_id = localStorage.getItem('store_id');
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (store_id) {
      config.headers.storeid = store_id;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Add response interceptor for error logging
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('Response error:', error.response);
    } else if (error.request) {
      console.error('Request error:', error.request);
    } else {
      console.error('General error:', error.message);
    }
    return Promise.reject(error);
  },
);

export default instance;
