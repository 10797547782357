import React from 'react';
import { Truck, Pocket, Clock, DollarSign, Percent } from 'feather-icons-react';

const Services = () => {
    return (
        <div className='container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3'>
            <div className='flex min-h-[200px] flex-col justify-center gap-1'>
                <Truck strokeWidth={1.5} className="w-9 h-9 mb-1" />
                <span className='text-[16px] font-semibold inline-block'>Free Delivery</span>
                <p className='text-sm italic text-[#666] m-0'>Free shipping on all order</p>
            </div>
            <div className='flex min-h-[200px] flex-col justify-center gap-1'>
                <Pocket strokeWidth={1.5} className="w-9 h-9 mb-1" />
                <span className='text-base font-semibold inline-block'>Big Saving Shop</span>
                <p className='text-sm italic text-[#666] m-0'>Save big every order</p>
            </div>
            <div className='flex min-h-[200px] flex-col justify-center gap-1'>
                <Clock strokeWidth={1.5} className="w-9 h-9 mb-1" />
                <span className='text-base font-semibold inline-block'>Online Support 24/7</span>
                <p className='text-sm italic text-[#666] m-0'>Support online 24 hours a day</p>
            </div>
            <div className='flex min-h-[200px] flex-col justify-center gap-1'>
                <DollarSign strokeWidth={1.5} className="w-9 h-9 mb-1" />
                <span className='text-base font-semibold inline-block'>Money Back Return</span>
                <p className='text-sm italic text-[#666] m-0'>Back guarantee under 7 days</p>
            </div>
            <div className='flex min-h-[200px] py-6 flex-col justify-center gap-1'>
                <Percent strokeWidth={1.5} className="w-9 h-9 mb-1" />
                <span className='text-base font-semibold inline-block'>Member Discount</span>
                <p className='text-sm italic text-[#666] m-0'>Onevery order over $120.00</p>
            </div>
        </div>
    )
}

export default Services