import React from 'react'

const HeroSection = () => {
    return (
        <div className='h-[600px] hero-bg flex items-center'>
            <div className="container">
                <div className='w-full lg:w-[50%] text-white'>
                    <span className='uppercase mb-4 inline-block px-4 py-1.5 text-black bg-[#ffe15f] text-sm font-medium rounded'>Weekend Discunts</span>
                    <h1 className='text-[45px] font-normal'>The New Branded</h1>
                    <h1 className='text-[45px] font-semibold'>Wireless Headphones</h1>
                    <span className='uppercase flex items-end gap-2 mt-3 mb-6 text-xs'>
                        <span className='mb-1.5'>From</span>
                        <h2 className='text-[#ffcc00] m-0 font-medium text-[28px]'>$69.99/-</h2>
                    </span>
                    <button className='btn-yellow'>
                        SHOP NOW
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HeroSection