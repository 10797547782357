import React from 'react'

const Categories = () => {
  return (
    <div className='container'>
        <h5 className='text-[20px] font-medium'>Shop by categories</h5>
        <div className='grid mt-8 grid-cols-1 min-[388px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-8'>
            <div className='flex text-center flex-col'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/01.webp' alt='category1'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/02.webp' alt='category2'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/03.webp' alt='category3'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/04.webp' alt='category4'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/05.webp' alt='category5'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/06.webp' alt='category6'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/07.webp' alt='category7'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/08.webp' alt='category8'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/09.webp' alt='category9'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/10.webp' alt='category10'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/11.webp' alt='category11'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/12.webp' alt='category12'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'> 
                    <img src='images/categories/13.webp' alt='category13'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
            <div className='flex text-center flex-col gap-'>
                <div className='w-[138px] h-[138x] mb-2 mx-auto rounded-full overflow-hidden flex items-center justify-center'>
                    <img src='images/categories/14.webp' alt='category14'/>
                </div>
                <span className='text-base font-medium'>Men</span>
                <span className='italic font-medium text-sm text-[#666666]'>items <sup>01</sup></span>
            </div>
        </div>
    </div>
  )
}

export default Categories