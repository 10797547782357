import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import Home from './Pages/Home';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from './Components/Footer';
import Product from './Pages/Product';
import PageNoFound from './Pages/PageNoFound';
import Login from './Pages/Login';
import Register from './Pages/Register';
import { useEffect } from 'react';
import { getClientData } from './Redux/Action/client.action';
import { useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import ForgotPassword from './Pages/ForgotPassword';
import NewPassword from './Pages/NewPassword';
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClientData());
  }, [])
  return (
    <>
      <Header />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: '14px',
          },
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path="/setnewpassword/:token" element={<NewPassword />} />
          <Route path='/product' element={<Product />} />
          <Route path='/*' element={<PageNoFound />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
