import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { handleForgotPassword } from '../Redux/Action/auth.action';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(handleForgotPassword({email}))
    }
    return (
        <section className="!bg-transparent">
            <div className="flex flex-col items-center justify-center px-6 py-8 max-w-screen">
                <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-black">
                    <img
                        className="inline-block max-w-[100px] min-[388px]:max-w-[140px] lg:max-w-full"
                        src="images/logo.svg"
                        alt="logo"
                    />
                </Link>
                <div className="w-full bg-transparent border border-[#e5e5e5] rounded-lg shadow md:mt-0 sm:w-8/12 xl:w-1/3 ">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-black md:text-2xl">
                            Forgot Password
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={(e) => handleSubmit(e)}>
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block mb-2 text-sm font-medium text-black"
                                >
                                    Your email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                    placeholder="name@company.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value )}
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={!email}
                                className={`${!email ? 'opacity-50 cursor-not-allowed' : 'btn-black'} w-full bg-[#222222] text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                            >
                                Send link
                            </button>
                            <p className="text-sm font-light text-black">
                                <Link
                                    to='/login'
                                    className="font-medium text-black hover:underline"
                                >
                                   Back to sign in
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword
