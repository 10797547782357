import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { handleSetPassword } from '../Redux/Action/auth.action';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import Countdown from 'react-countdown';

const NewPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const token = params.token;

    const [loginData, setLoginData] = useState({
        npassword: '',
        cpassword: ''
    });
    const [eyeopen, setEyeopen] = useState(false);
    const [eyeopen1, setEyeopen1] = useState(false);
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    const [nPassError, setNPassError] = useState(false);
    const [cPassError, setCPassError] = useState(false);
    const [tokenValid, setTokenValid] = useState(true);

    const Completionist = () => <p className="text-red-500">Token Expired</p>;

    let ExpiryTime = localStorage.getItem('expiretime');
    if (ExpiryTime) {
        ExpiryTime = new Date(ExpiryTime);
    } else {
        const newExpiryTime = new Date(Date.now() + 600000);
        ExpiryTime = newExpiryTime;
        localStorage.setItem('expiretime', newExpiryTime.toISOString());
    }

    useEffect(() => {
        if (ExpiryTime <= new Date()) {
            setTokenValid(false);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (tokenValid) {
            dispatch(handleSetPassword(loginData, token));
        }
    };

    return (
        <section className="!bg-transparent">
            <div className="flex flex-col items-center justify-center px-6 py-8 max-w-screen">
                <div className="w-full bg-transparent border border-[#e5e5e5] rounded-lg shadow md:mt-0 sm:w-8/12 xl:w-1/3 ">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-black md:text-2xl">
                            Change your password
                        </h1>
                        Token Expires in:
                        {tokenValid ? (
                            <Countdown
                                date={ExpiryTime}
                                daysInHours={true}
                                onComplete={() => setTokenValid(false)} // Handle token expiry
                            />
                        ) : (
                            <Completionist />
                        )}
                        <form className="space-y-4 md:space-y-6" onSubmit={(e) => handleSubmit(e)}>
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block mb-2 text-sm font-medium !text-black dark:text-white"
                                >
                                    New Password
                                </label>
                                <div className="flex items-center relative justify-between">
                                    <input
                                        type={eyeopen ? 'text' : 'password'}
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                        value={loginData?.npassword}
                                        onChange={(e) =>
                                            setLoginData({ ...loginData, npassword: e.target.value })
                                        }
                                        onBlur={(e) => {
                                            if (passwordRegx?.test(e.target.value)) {
                                                setNPassError(false);
                                            } else {
                                                setNPassError(true);
                                            }
                                        }}
                                        required
                                    />
                                    <div
                                        onClick={() => setEyeopen(!eyeopen)}
                                        className="cursor-pointer absolute right-2"
                                    >
                                        {eyeopen ? <EyeSlash /> : <Eye />}
                                    </div>
                                </div>
                                {nPassError && (
                                    <p className="m-0 text-red-400 font-semibold">
                                        Password must be 8 characters long, contain at least 1 uppercase letter,
                                        1 lowercase letter, 1 special character, and 1 number.
                                    </p>
                                )}
                            </div>
                            <div>
                                <label
                                    htmlFor="confirm-password"
                                    className="block mb-2 text-sm font-medium !text-black dark:text-white"
                                >
                                    Confirm Password
                                </label>
                                <div className="flex items-center relative justify-between">
                                    <input
                                        type={eyeopen1 ? 'text' : 'password'}
                                        name="confirm-password"
                                        id="confirm-password"
                                        placeholder="••••••••"
                                        className="bg-transparent border !text-black rounded-lg block focus:border-[#ffe15f] w-full p-2.5"
                                        value={loginData?.cpassword}
                                        onChange={(e) =>
                                            setLoginData({ ...loginData, cpassword: e.target.value })
                                        }
                                        onBlur={(e) => {
                                            if (loginData?.npassword === e.target.value) {
                                                setCPassError(false);
                                            } else {
                                                setCPassError(true);
                                            }
                                        }}
                                        required
                                    />
                                    <div
                                        onClick={() => setEyeopen1(!eyeopen1)}
                                        className="cursor-pointer absolute right-2"
                                    >
                                        {eyeopen1 ? <EyeSlash /> : <Eye />}
                                    </div>
                                </div>
                                {cPassError && (
                                    <p className="m-0 text-red-400 font-semibold">Passwords must match.</p>
                                )}
                            </div>
                            <button
                                type="submit"
                                disabled={!loginData?.npassword || !loginData?.cpassword || !tokenValid}
                                className={`${
                                    !loginData?.npassword || !loginData?.cpassword || !tokenValid
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'btn-black'
                                } w-full text-white bg-[#222222] font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NewPassword;
