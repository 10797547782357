import React from 'react'
import ProductCard from './ProductCard'
import { ChevronLeft, ChevronRight } from 'feather-icons-react'

const ProductList = () => {
    const StarFill = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={14}
                height={14}
                viewBox="0 0 24 24"
                fill="#000"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather me-1 feather-star"
            >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>
        )
    }
    return (
        <div className='container'>
            <div className='flex gap-2 mb-4 items-center justify-between w-full'>
                <div className='flex items-center gap-2'>
                    <div className='w-[40px] h-[40px] rounded-[6px] bg-[#ffe15f]'></div>
                    <div>
                        <span className='text-base font-medium'>Whispo</span>
                        <span className='text-xs font-medium flex items-center'><StarFill />4.3 (150)</span>
                    </div>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='w-[40px] h-[40px] rounded-full flex items-center justify-center bg-[#eaeaea]'>
                        <ChevronLeft strokeWidth={2} className="w-5 h-5" />
                    </div>
                    <div className='w-[40px] h-[40px] rounded-full flex items-center justify-center bg-[#eaeaea]'>
                        <ChevronRight strokeWidth={2} className="w-5 h-5" />
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-5 gap-5'>
                <ProductCard />
                <ProductCard />
                <ProductCard bedge={'42%'} comparePrice={'30.00'} />
                <ProductCard />
                <ProductCard />
            </div>
        </div>
    )
}

export default ProductList