import API from "../../API";
import ToastNotification from "../../Controllers/ToastNotification";
import { AUTH, AUTH_FAIL, ERROR, REGISTER } from "../action.type";

export const handleLogin = (data, rememberMe) => {
  return async (dispatch) => {
    try {
      const userLogin = await API({ url: '/user/login', method: 'post', data: { ...data, remember_me: rememberMe } });
      if (userLogin?.data) {
        dispatch({
          type: AUTH,
          payload: { ...userLogin?.data?.data, is_loggedIn: true },
        });
        ToastNotification.success('Login Successfully!');
        if (rememberMe) {
          localStorage.setItem('rememberme', true)
          localStorage.setItem('token', userLogin?.data?.data?.token)
        } else {
          localStorage.setItem('rememberme', false)
          sessionStorage.setItem('token', userLogin?.data?.data?.token)
        }
      }
    } catch (error) {
      ToastNotification.error(error);
      dispatch({
        type: AUTH_FAIL,
        payload: error,
      });
    }
  };
};
export const handleRegister = (data) => {
  return async (dispatch) => {
    try {
      const addCustomer = await API({
        url: '/user/addCustomer', method: 'post', data: {
          userInfo: {
            email: data?.email,
            password: data?.password,
            first_name: data?.first_name,
            last_name: data?.last_name,
            phone: data?.phone,
            contry_code: data?.contry_code
          },
          customerInfo: {
            addresses: [
              data?.addressData
            ],
          },
          custom_fields: data?.custom_fields
        }
      });
      if (addCustomer?.data) {
        dispatch({
          type: REGISTER,
          payload: addCustomer,
        });
        ToastNotification.success('User Register Successfully!');
        localStorage.setItem('rememberme', false)
        sessionStorage.setItem('token', addCustomer?.data?.data?.token)
        return Promise.resolve();
      }
    } catch (error) {
      ToastNotification.error(error);
      dispatch({
        type: ERROR,
        payload: error,
      })
    }
  };
};
export const handleForgotPassword = (data) => {
  return async (dispatch) => {
    try {
      const sendForgotPasswordLink = await API({ url: `/user/sendForgotPasswordLink`,method: 'post', data: data});
      if (sendForgotPasswordLink?.status === 200 || sendForgotPasswordLink?.status === 304) {
        ToastNotification.success("Email sent sucessfully!");
      }
    } catch (error) {
      ToastNotification.error(error);
      dispatch({
        type: ERROR,
        payload: error,
    })
    }
  };
};
export const handleSetPassword = (data, token) => {
  return async (dispatch) => {
    try {
      const sendForgotPasswordLink = await API({ url: `/user/forgot-password/${token}`, method: 'post', data: {
          new_password: data?.npassword,
          confirm_password: data?.cpassword,
        }});
      if (sendForgotPasswordLink?.status === 200 || sendForgotPasswordLink?.status === 304) {
        ToastNotification.success("Password set sucessfully!");
        localStorage.removeItem("expiretime")
        window.location.href= '/';
      }
    } catch (error) {
      ToastNotification.error(error);
      dispatch({
        type: ERROR,
        payload: error,
    })
    }
  };
};